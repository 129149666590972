<template>
  <div class="page form-form">
    <h2>用户信息</h2>

    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane label="个人信息" name="info">
        <el-form class="form" ref="form" :model="info" size="small" label-width="120px">
          <el-form-item label="UID">
            {{ info.id }}
          </el-form-item>
          <el-form-item label="昵称">
            <el-input v-model="info.nickname"/>
          </el-form-item>
          <el-form-item label="用户头像">
            <img  :src="info.avatar" />
          </el-form-item>
          <el-form-item label="真实名称">
            <el-input v-model="info.realname"/>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="info.tel"/>
          </el-form-item>
          <el-form-item label="性别">
            <el-input v-model="info.gender"/>
          </el-form-item>
          <el-form-item label="积分">
            <el-input-number v-model="info.point_num" :min="0" controls-position="right"/>
          </el-form-item>
          <!--          <el-form-item label="权限">-->
          <!--              <el-checkbox v-model="info.allow_login">允许登录</el-checkbox>-->
          <!--&lt;!&ndash;              <el-checkbox v-model="info.allow_buy">允许购买和听课</el-checkbox>&ndash;&gt;-->
          <!--          </el-form-item>-->
          <el-form-item label="用户类型">
            <el-select v-model="info.role" placeholder="选择类型">
              <el-option label="管理员" value="admin"/>
              <el-option label="啦啦之星" value="啦啦之星"/>
              <el-option label="用户" value="用户"/>
            </el-select>
            <span class="hr-line"></span>
            <el-select v-model="info.type2" placeholder="选择类型">
              <el-option v-for="t in $dbus.config.enums_user_types_2" :key="t"
                         :label="t" :value="t"/>
            </el-select>
          </el-form-item>
          <el-form-item label="时间">
            <ul>
              <li>注册时间：{{ info.datetime_created }}</li>
              <li>上次登陆时间：{{ info.datetime_last_logged }}</li>
              <li>上次购物时间：{{ info.datetime_last_bought }}</li>
            </ul>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitInfo">提交</el-button>
            <el-button @click="load">刷新</el-button>
            <el-button @click="dialogVisible = true">登陆设置</el-button>
            <el-button @click="onRemoteSession">清除登录信息</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="消费记录" name="order">
        <el-table :data="orders.records">
          <el-table-column label="订单号" prop="id" width="100"/>
          <el-table-column label="总金额" prop="fee_original" width="100"/>
          <el-table-column label="付款金额" prop="fee_paid" width="100"/>
          <el-table-column label="商品内容">
            <template slot-scope="scope">
              <el-tag v-for="g in scope.row.goods" :key="g.title">{{ g.title }} x {{ g.num }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status" width="100"/>
          <el-table-column label="付款时间" prop="datetime_paid" width="160"/>
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button-group>
                <el-button size="mini">详情</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="课程记录" name="course">
        <el-table :data="courses.records">
          <el-table-column label="学习课程" prop="title"/>
          <el-table-column label="课程作者" prop="user.realname"/>
          <el-table-column label="首次学习时间" prop="a" width="160"/>
          <el-table-column label="上次学习时间" prop="a" width="160"/>
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="onAddCoupon">详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!--      <el-tab-pane label="行为记录">-->
      <!--      </el-tab-pane>-->
      <el-tab-pane label="推广记录" name="invitation">
        <section>
          <h3>统计数据</h3>
          <el-form class="form" size="small" label-width="220px">
            <el-form-item label="分享次数">
              <span>{{ tg_share.total || 0 }}</span>
            </el-form-item>
            <el-form-item label="推广VIP总数">
              <span>{{ tg_vip.total || 0 }}</span>
            </el-form-item>
            <el-form-item label="推广链接 点击次数">
              <span>{{ tg_click.total || 0 }}</span>
            </el-form-item>
            <el-form-item label="推广链接 有效转化总数">
              <span>{{ tg_order.total || 0 }}</span>
            </el-form-item>

          </el-form>
        </section>

        <section>
          <h3>推广链接 分享统计</h3>
          <el-table :data="tg_share.contains">
            <el-table-column label="UID" prop="user.id" width="100"/>
            <el-table-column label="用户名" prop="user.realname" width="140"/>
            <el-table-column label="分享链接" prop="xid">
              <template slot-scope="scope">
                <xid-link :xid="scope.row.xid"/>
              </template>
            </el-table-column>
            <el-table-column label="行为" prop="link" width="100" :formatter="() => '分享'"/>
            <el-table-column label="分享时间" prop="datetime_created" width="160"/>
          </el-table>

          <el-pagination class="pagination"
                         layout="total, prev, pager, next"
                         @current-change="() => loadInvitationStat('share')"
                         :current-page.sync="tg_share.page"
                         :page-size="10"
                         :total="tg_share.total"/>
        </section>

        <section>
          <h3>推广链接 点击统计</h3>
          <el-table :data="tg_click.contains">
            <el-table-column label="UID" prop="user.id" width="100"/>
            <el-table-column label="用户名" prop="user.realname" width="140"/>
            <el-table-column label="分享链接" prop="xid">
              <template slot-scope="scope">
                <xid-link :xid="scope.row.xid"/>
              </template>
            </el-table-column>
            <el-table-column label="行为" prop="link" width="100" :formatter="() => '点击'"/>
            <el-table-column label="点击时间" prop="datetime_created" width="160"/>
          </el-table>
          <el-pagination class="pagination"
                         layout="total, prev, pager, next"
                         @current-change="() => loadInvitationStat('click')"
                         :current-page.sync="tg_click.page"
                         :page-size="10"
                         :total="tg_click.total"/>
        </section>

        <section>
          <h3>推广链接 购买统计</h3>
          <el-table :data="tg_order.contains">
            <el-table-column label="订单用户">
              <template slot-scope="scope">
                <xid-link :xid="'user-' + scope.row.user.id"
                          :name="scope.row.user.nickname || scope.row.user.nickname"/>
              </template>
            </el-table-column>
            <el-table-column label="订单ID">
              <template slot-scope="scope">
                <xid-link :xid="'order-' + scope.row.id" :name="scope.row.id"/>
              </template>
            </el-table-column>
            <el-table-column label="联系方式" prop="recv_tel"/>
            <el-table-column label="付款金额" prop="fee_paid"/>
            <el-table-column label="状态" prop="status"/>
            <el-table-column label="注册时间" prop="datetime_created" width="160"/>
          </el-table>
          <el-pagination class="pagination"
                         layout="total, prev, pager, next"
                         @current-change="() => loadInvitationStat('order')"
                         :current-page.sync="tg_order.page"
                         :page-size="10"
                         :total="tg_order.total"/>
        </section>

        <section>
          <h3>推广链接 VIP用户</h3>
          <el-table :data="tg_vip.contains">
            <el-table-column label="订单用户">
              <template slot-scope="scope">
                <xid-link :xid="'user-' + scope.row.user.id"
                :name="scope.row.user.nickname || scope.row.user.nickname"/>
              </template>
            </el-table-column>
            <el-table-column label="订单ID">
              <template slot-scope="scope">
                <xid-link :xid="'order-' + scope.row.id" :name="scope.row.id"/>
              </template>
            </el-table-column>
            <el-table-column label="联系方式" prop="recv_tel"/>
            <el-table-column label="付款金额" prop="fee_paid"/>
            <el-table-column label="状态" prop="status"/>
            <el-table-column label="注册时间" prop="datetime_created" width="160"/>
            <el-table-column label="奖励">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.has_rewarded">已奖励</el-tag>
                <el-tag type="info" v-else>未奖励</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="奖励时间" prop="datetime_rewarded" width="160"/>
          </el-table>
          <el-pagination class="pagination"
                         layout="total, prev, pager, next"
                         @current-change="() => loadInvitationStat('vip')"
                         :current-page.sync="tg_vip.page"
                         :page-size="10"
                         :total="tg_vip.total"/>
        </section>

      </el-tab-pane>
      <el-tab-pane label="优惠券" name="coupon">
        <el-table :data="coupons.records">
          <el-table-column label="ID" prop="id" width="100"/>
          <el-table-column label="标题" prop="coupon.title"/>
          <el-table-column label="使用状态" prop="status" width="100"/>
          <el-table-column label="操作" width="160">
            <template slot="header" slot-scope="scope">
              <el-button size="mini" type="primary"
                         @click="onAddCoupon">添加
              </el-button>
            </template>
            <template slot-scope="scope">
              <el-button-group>
                <el-button size="mini" @click="$router.push({name: 'coupon-info', query:{id: scope.row.id}})">详情
                </el-button>
                <!--<el-button size="mini" type="danger"-->
                <!--@click="onDeleteCoupon(scope.row)">删除-->
                <!--</el-button>-->
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="会员" name="vip">
        <el-form class="form" :model="info" size="small" label-width="120px">
          <el-form-item label="花粉会员 状态">
            <el-switch v-model="info.is_vip_hf"/>
          </el-form-item>
          <el-form-item label="VIP会员 状态">
            <el-switch v-model="info.is_vip"/>
          </el-form-item>
          <el-form-item label="VIP 开始时间">
            <el-date-picker
                v-model="info.datetime_vip_start"
                type="date" value-format="yyyy-MM-dd"
                placeholder="选择到期日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="VIP 到期时间">
            <el-date-picker
                v-model="info.datetime_vip_expired"
                type="date" value-format="yyyy-MM-dd"
                placeholder="选择到期日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitInfo">提交</el-button>
            <el-button @click="onAddVipRedeem">添加 VIP 券</el-button>
          </el-form-item>

          <el-form-item label="VIP 购买记录">
            <ol>
              <li v-for="item in vip.orders">
                【订单ID: {{ item.id }}】 【金额：{{ item.fee_paid }}】 于 {{ item.datetime_paid }} 购买成功
              </li>
            </ol>
          </el-form-item>

          <el-form-item label="VIP 兑换记录">
            <ol>
              <li v-for="item in vip.redeems">
                【券ID: {{ item.id }}】 【券码：{{ item.code }}】 于 {{ item.datetime_used }} 兑换成功
              </li>
            </ol>
          </el-form-item>
        </el-form>
      </el-tab-pane>

    </el-tabs>

    <el-dialog title="设置登陆信息" width="380px" v-loading="loading"
               :visible.sync="dialogVisible">
      <el-form class="dialog-form" ref="form" size="mini" :model="dialogForm" label-width="100px">
        <el-form-item label="用户名">
          <el-input v-model="info.username" placeholder="请设置新的登陆密码"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="info.password" show-password></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmitInfo">提交</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import XidLink from "@/comps/xid-link";

export default {
  components: {XidLink},
  data() {
    return {
      loading: false,
      loading2: false,
      activeTab: this.$route.query.tab || 'info',
      dialogVisible: false,
      dialogForm: {},
      id: this.$route.query.id,
      info: {},
      orders: [],
      courses: [],
      actions: [],
      coupons: [],
      tg: [],
      vip: [],
      rules: {},
      tg_list: [],
      tg_page: 1,
      tg_total: 1,
      user_courses: [],

      tg_click: {},
      tg_share: {},
      tg_order: {},
      tg_vip: {},
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      this.$api.request('/user/get_details_for_admin', {uid: this.id}).then(data => {
        this.loading = false
        this.info = data.info
        this.orders = data.orders
        this.courses = data.courses
        this.actions = data.actions
        this.coupons = data.coupons
        this.vip = data.vip
      })

      await this.loadInvitationStat()
      // this.$api.request('/coupon/list_user', {id: this.id}).then(data => {
      //   this.coupons = data.filter(c => c.status === '未使用')
      // })
      // this.$api.request('/user/list_tg_records', {uid: this.id}).then(data => {
      //   this.tg_list = data
      // })
    },
    async loadInvitationStat(name) {

      const _req = n => this.$api.request('/user/get_user_invitation_stat',
          {uid: this.id, name: n})
      if (!name || name === 'click') {
        this.tg_click = await _req('click')
      }

      if (!name || name === 'share') {
        this.tg_share = await _req('share')
      }

      if (!name || name === 'order') {
        this.tg_order = await _req('order')
      }

      if (!name || name === 'vip') {
        this.tg_vip = await _req('vip')
      }

    },
    onAddCoupon() {
      this.$prompt('请输入券号', '为用户添加券', {}).then(({value}) => {
        this.$api.request('/coupon/recv', {uid: this.id, code: value, admin_mode: true}).then(data => {
          console.log(data)
          this.$message.success('添加成功')
          this.load()
        })
      })
    },
    onAddVipRedeem() {
      this.$prompt('请输入券号').then(({value}) => {
        this.$api.request('/redeem/recv', {uid: this.id, code: value, admin_mode: true}).then(data => {
          this.$message.success('添加成功')
          this.load()
        })
      })
    },
    onSubmitInfo() {
      this.$api.request('/user/update', this.info).then(data => {
        this.info = data
        this.$message.success('更新成功!')
      })
    },
    async onRemoteSession(){
      await this.$api.request('/user/remote_session', {id: this.info.id})
      await this.$message.success('清除成功!')
      await this.load()

    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  margin: 22px 24px;
  color: #5a6268;
}
</style>

